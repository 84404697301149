import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || "#F1BCB7"};
  color: ${(p) => (p.color ? "#fff8ee" : "#fff8ee")};
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: 1px solid #F1BCB7;
  border-radius: 4px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);

  &:hover,
  &:active,
  &:focus {
    color: #fff8ee;
    border: ##F1A9A2);
    background-color: #F1A9A2;
  }
`;
