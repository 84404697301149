import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'


import i18n from "i18next";
import {
  FooterSection,
  Extra,
  LogoContainer,
  Para,
  Chat,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={12} md={24} sm={24} xs={24}>
              <Language>{t("Contact")}</Language>
              <Para>
                {t(`Do you have any questions? Feel free to reach out.`)}
              </Para>
              <a href="mailto:amantanyawedding@outlook.com">
                <Chat>{t(`Email Us`)}</Chat>
              </a>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <Language>{t("Address")}</Language>
              <Para>Vignoble de l’Orpailleur</Para>
              <Para>1086 Rue Bruce</Para>
              <Para>Dunham, QC J0E 1M0</Para>
              <Para>Canada</Para>
            </Col>

          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            style={{ paddingTop: "3rem" }}
          >
            <Col lg={24} md={24} sm={24} xs={24}>
              <Para>Made by Aman with lots of <FontAwesomeIcon icon={faHeart} /> for Tanya</Para>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
